/* Custom */

:root {
  --color-dark: #0e113c;
  --color-med: #03033e;
  --color-light: #1e1d80;
  --color-accent: #f6c243;
  --color-bg-footer-top: #232c37;
  --color-bg-footer-bottom: #1c222a;
  --font-body: 'Montserrat', sans-serif;
  --font-heading: 'Oswald', sans-serif;
}

.bg-med {
  background-color: var(--color-med);
}

.bg-footer-top {
  background-color: var(--color-bg-footer-top);
}

.bg-footer-bottom {
  background-color: var(--color-bg-footer-bottom);
}

.text-accent {
  color: var(--color-accent) !important;
}

.link-accent {
  color: var(--color-accent);
}

.link-accent:hover {
  color: var(--color-accent);
  filter: brightness(0.6);
}

.link-light {
  color: white;
  text-decoration: none;
  font-size: 12px;
}

.link-light:hover {
  color: white;
  filter: brightness(0.7)
}

.bi {
  display: inline-block;
  vertical-align: -0.125em;
}

.bg-img {
  /* background: url("./img/Background.jpg") no-repeat top center fixed; */
  /* background: url('./img/Background_option2.png') no-repeat top center fixed; */
  background: url('./img/Background_option2_edited.png') no-repeat top center fixed;
  background-size: cover;
}

.small-container {
  max-width: 50em;
}

.text-font-body {
  font-family: var(--font-body);
}

.text-font-heading {
  font-family: var(--font-heading);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.02em;
}

.text-font-logo {
  font-family: var(--font-heading);
  font-size: 40px;
  font-weight: 600;
}

.main-bottom-shadow {
  box-shadow: inset 0 -0.25em 1em rgba(0, 0, 0, 0.4)
}

.main-padding-bottom {
  padding-bottom: 8em;
}

.font-size-12 {
  font-size: 12px;
}

.border-accent {
  border-color: var(--color-accent) !important;
}

.gold-border {
  border-top: 3px solid var(--color-accent);
  border-bottom: 3px solid var(--color-accent);
}

.gold-bottom {
  border-bottom: 1px solid var(--color-accent);
}

.list-group-item.bg-slight-transparent {
  background-color: rgba(0, 0, 0, 0.2);
}

.list-group-item.list-group-item-gold {
  border-color: var(--color-accent);
  background-color: var(--color-med);
  color: var(--color-accent);
}
